import api from "@/store/apiUtil";

const authStore = {
  namespaced: true,
  state: () => ({
    token: "", //token
    login_result: null,
    login_data: null,
    jsessionid:"",        //Arthur.Kim 2022.04.29 세션ID
    //jsessionid:"",      //Arthur.Kim 2022.04.29 세션ID
    access_token:"",      //Arthur.Kim_20220924 알림톡토큰
    expires_in:"",        //Arthur.Kim_20220924 알림톡토큰유효시간
  }),
  getters: {
    GE_AUTH_TOKEN: (state) => state.token,
    GE_AUTH_LOGIN_RESULT: (state) => state.login_result,
    GE_AUTH_LOGIN_DATA: (state) => state.login_data,
    GE_AUTH_JSESSIONID: (state) => state.jsessionid,      //Arthur.Kim 2022.04.29 세션ID
    //GE_AUTH_JSESSIONID: (state) => state.jsessionid,    //Arthur.Kim 2022.04.29 세션ID

    GE_ACCESS_TOKEN: (state) => state.access_token,       //Arthur.Kim_20220924 알림톡토큰
    GE_EXPIRES_IN: (state) => state.expires_in,           //Arthur.Kim_20220924 알림톡토큰유효시간
    GE_CAP_0510: (state) => state.cap0510,                //Arthur.Kim_20220924 대기페이지 오픈 여부
  },
  mutations: {
    MU_AUTH_TOKEN: (state, data) => {
      // console.log("###########################################################");
      // console.log("### [authStore][mutations][MU_AUTH_TOKEN]");
      // console.log("### data=",data);
      // console.log("###########################################################");
      /*
                여기서는 payload를 객체로 받습니다.
                payload를 객체로 받으면, mutation를 조금더 유연하게 사용할 수 있기는 합니다.
            */
      state.token = data.access_token;
    },
    MU_AUTH_LOGIN_RESULT: (state, data) => {
      state.login_result = data.code;
      state.login_data = data.data;
    },
    MU_AUTH_LOGOUT_RESULT: (state, data) => {
      state.login_result = data.code;
    },
    /**
     * 알림톡 alarmTalk Arthur.Kim_20220924
     * @param {*} state 
     * @param {*} data 
     */
    MU_ALARM_TALK: (state, data) => {
      state.access_token = data.access_token;
      state.expires_in = data.expires_in;
    },
  },
  actions: {

    /**
     * 알림톡 alarmTalk Arthur.Kim_20220924
     * @param {*} param0 
     * @param {*} payload 
     */
    AC_ALARM_TALK: ({commit}, payload) => {
      commit('MU_ALARM_TALK', payload);
    },

    /**
     * 대기중 페이지 오픈 여부 Arthur.Kim_20220924
     * @param {*} param0 
     * @param {*} payload 
     */
    AC_CAP_0510: ({commit}, payload) => {  //cap0510
      commit('MU_CAP_0510', payload);
    },

    //로그인
    async AC_AUTH_LOGIN({ commit }, data) {
      //임시 테스트 데이터
      let set_data = {
        code: 0,
        data: data,
      };

      let response_data;

      await api
        .post("api/login", {
          username: data.username,
          password: data.userpassword,
        })
        .then((response) => {
          if(!response.data.HEADER.ERROR_FLAG) {
            set_data.code = 1;
            set_data.data = response.data.DATA[0];
          }

          response_data = response;

          //로그인 성공/실패 처리
          commit("MU_AUTH_LOGIN_RESULT", set_data);
        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("login.error", error);

          set_data.code = 0;
          //로그인에러 처리
          commit("MU_AUTH_LOGIN_RESULT", set_data);
        });

      return response_data;
    },
    //로그아웃
    async AC_AUTH_LOGOUT({ commit }, data) {
      let set_data = {
        code: null,
      };

      let response_data;

      await api
        .post("api/logout", {
          ACCESS_TOKEN: sessionStorage.getItem("token"),
          JSESSIONID: sessionStorage.getItem("jsessionid")
        })
        .then((response) => {
          //로그아웃 처리
          commit("MU_AUTH_LOGOUT_RESULT", set_data);
          window.sessionStorage.clear();

          response_data = response;
        })
        .catch((error) => {
          // 에러인 경우 처리
          console.error("logout.error", error);

          set_data.code = -1;
          //로그인에러 처리
          commit("MU_AUTH_LOGIN_RESULT", set_data);
        });

      return response_data;
    },
  },
};

export default authStore;
