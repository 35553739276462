<template>
  <v-snackbar
    v-model="toast.act"
    timeout="1500"
    transition="toast-transition"
    :class="toast.class"
  >
    <template v-if="toast.hasToastIcon === true">
      <v-icon :class="toast.icon"></v-icon>
    </template>
    {{ toast.msg }}
  </v-snackbar>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ToastDefault",
  data: () => ({}),
  computed: {
    ...mapGetters({
      toast: "toastStore/GE_TOAST",
    }),
  },
};
</script>

<style></style>
