<template>
  <div class="layout-mdi d-flex">
    <div class="">
      <v-tabs 
        background-color="transparent"
        slider-size="0"
        class="layout-mdi-tabs"
        v-model="active_tab_num"
      >
        <draggable
          class="d-flex"
          ghost-class="ghost"
          draggable=".v-tab"
          @start="tabs_dragging = true"
          @end="tabs_dragging = false"
        >
          <v-tab 
            v-for="(tab, index) in tabs"
            :key="tab.id"
            class="layout-mdi-tab mr-3"
            :class="tab.isHome == true ? 'isHome' : ''"
            @click="moveTab(tab)"
          >
            <span :class="[tab.icon]"></span>
            {{ tab.title }}
            <v-btn
              icon
              @click.prevent.stop="removeTab(tab, index)"
              v-show="tab.default !== 'Y'"
              class="ml-auto"
            >
              <span class="svg-close20"></span>
            </v-btn>
          </v-tab>
        </draggable>
      </v-tabs>
    </div>

    <v-btn
      @click="allRemoveTab"
      class="layout-mdi--allclose"
      :class="{ active: allCloseTog }"
    >
      전체 탭 닫기
      <!-- <span class="svg-arrow-down20 ml-1"></span> -->
    </v-btn>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  export default {
    name: "MdiArea",
    components: {
      draggable,
    },
    data() {
      return {
        allCloseTog: false,
      };
    },

    mounted() {
      //mixin에 있는 default_tab 기준으로 탭을 초기화 한다
      this.$store.dispatch("commonStore/AC_COMMON_INIT_TAB", this.default_tab);

      //컴포넌트 활성화시 home 메뉴로 이동한다
      //새로고침시에도 home으로 이동한다
      if (this.$route.fullPath !== "/home") { // 수정전 /home @modi M810401
        this.$router.push("/home");
      }
    },

    computed: {
      active_tab_num: {
        get() {
          return this.ge_active_tab_num;
        },
        set() {},
      },
      active_tab_id: {
        get() {
          return this.ge_active_tab_id;
        },
        set() {},
      },
    },

    watch: {
      //활성화탭 이름이 변경되면 router를 변경한다
      ge_active_tab_title() {
        //변경된 탭이 활성화되어 있는 탭과 다르다면 router를 변경한다
        if (this.tabs[this.active_tab_num].link !== this.$route.path) {
          var linkto = this.tabs[this.active_tab_num].link.replace("/", "");
          this.$router.push(linkto);
        }
      },
    },

    methods: {

      //탭 변경시 이벤트
      moveTab(tab) {
        this.$store.dispatch("commonStore/AC_COMMON_ACTIVE_TAB", {
          id: tab.id,
          title: tab.title,
        });
      },

      changeState(type) {
        this.$store.commit("userStore/MU_USER_STATE", { ustate: type });
      },

      //탭 삭제시 이벤트
      removeTab(tab, index) {
        this.tabs.splice(index, 1);
        this.$store.dispatch("commonStore/AC_COMMON_DEACTIVE_TAB", {
          id: tab.id,
          title: tab.title,
        });
      },

      //전체 탭 삭제시 이벤트
      allRemoveTab() {
        this.allCloseTog = !this.allCloseTog;
        //mixin에 있는 default_tab 기준으로 탭을 초기화 한다
        this.$store.dispatch("commonStore/AC_COMMON_DELETE_ALL_TAB", "");
      },

    },
  };
</script>

<style></style>
