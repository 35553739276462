<template>
  <v-app>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "AuthLayout",
  components: {
  },
  data: () => ({
  }),
  methods: {
  }
};
</script>

<style scoped>
.v-main {
  background: #FFF !important
}
</style>