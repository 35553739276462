//index.js
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

import VueSwal from 'vue-swal'
Vue.use(VueSwal);   // 메시자창 lib

//알림톡
const tokenName = 'hlw-t'
const key = 'dsoxv94z7sbgpooiyp73efi28w55t8iw' // 카카오톡 전송 키값
const userid = 'mainframe' // 알리고 아이디



import createPersistedState from "vuex-persistedstate";
import authStore from "./modules/authStore";
import commonStore from "./modules/commonStore";
import userStore from "./modules/userStore";
import toastStore from "./modules/toastStore";
import alertStore from "./modules/alertStore";
import messages from "./modules/messages";                            //톡관련 추가_Arthur.Kim

Vue.prototype.store  = messages;                                       //톡관련 추가_Arthur.Kim

const store = new Vuex.Store({
  modules: {
    authStore,
    commonStore,
    userStore,
    toastStore,
    alertStore,
    messages,                                                          //톡관련 추가_Arthur.Kim
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["authStore", "commonStore", "userStore"
      //, "messages"
      ],    //톡관련 추가_Arthur.Kim 
    }),
  ],
});

export default store;
