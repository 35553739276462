import Vue from "vue";
import VueRouter from "vue-router";
import DefaultLayout from "@/layouts/default/index";
import AuthLayout from "@/layouts/auth/index";
import Login from "@/views/auth/Login";
import Home from "@/views/page/Home";
// import Commend from "@/views/help/Commend";
// import Layout from "@/views/help/Layout";
// import Menual from "@/views/help/Menual";
// import Guide from "@/views/help/Guide";
// import Sample from "@/views/help/Sample";
// import IaList from "@/views/help/IaList";

Vue.use(VueRouter);

import store from "../store";

//token 인증 영역 (일단 token이 있기만하면 인증완료)
const beforeAuth = (isAuth) => (from, to, next) => {
  /*
  let isAuthenticated = store.getters["authStore/GE_COMMON_TOKEN"];

  if (!isAuthenticated && isAuth) {
    if (sessionStorage.getItem("token")) {
      isAuthenticated = sessionStorage.getItem("token");
      store.commit("authStore/MU_AUTH_TOKEN", {
        access_token: isAuthenticated,
      });
    }
  }

  // if ((isAuthenticated && isAuth) || (!isAuthenticated && !isAuth)) {
  if ((isAuthenticated && isAuth) || !isAuth) {
    return next();
  } else {
    // 로그인 화면으로 이동
    next("/login");
  }
  */

  //token이 없으면 login 페이지를 이동
  if ((window.sessionStorage.getItem("token") && isAuth) || !isAuth) {
    return next();
  } else {
    // 로그인 화면으로 이동
    next("/login");
  }

  return next();
};

//AuthenticationLayout: header와 aside(메뉴)가 없는 백지 레이아웃 (로그인, 회원가입, 비밀번호 찾기등의 페이지에 사용)
//DefaultLayout: 기본적인 header와 aside(메뉴)가 있는 레이아웃 (거의 모든 메뉴에 적용)
const routes = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "/",
        name: "기본",
        component: Login,
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/login",
        name: "로그인",
        component: Login,
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/COguide",
        name: "가이드",
        component: () => import("@/views/help/Guide"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CODrag",
        name: "드레그",
        component: () => import("@/components/DragAndDrop"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAM0600",
        name: "사전방문등록",
        component: () => import("@/views/page/ca/CAM0600"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0700",
        name: "이벤트 설문",
        component: () => import("@/views/page/ca/CAP0700"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAM0610",
        name: "사전방문등록수정",
        component: () => import("@/views/page/ca/CAM0610"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAM0700",
        name: "웨이팅확인",
        component: () => import("@/views/page/ca/CAM0700"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0510",
        name: "대기현황",
        component: () => import("@/views/page/ca/CAP0510"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0520",
        name: "상담현황",
        component: () => import("@/views/page/ca/CAP0520"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0530",
        name: "현장방문확인",
        component: () => import("@/views/page/ca/CAP0530"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0540",
        name: "현장방문등록",
        component: () => import("@/views/page/ca/CAP0540"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0550",
        name: "상담예약",
        component: () => import("@/views/page/ca/CAP0550"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0560",
        name: "테블릿 신규 상담예약",
        component: () => import("@/views/page/ca/CAP0560"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0560M",
        name: "신규 모바일 상담예약",
        component: () => import("@/views/page/ca/CAP0560M"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0570M",
        name: "신규 모바일 상담예약(설문버전)",
        component: () => import("@/views/page/ca/CAP0570M"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0100",
        name: "예약입력",
        component: () => import("@/views/page/ca/CAP0100"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0200",
        name: "상담중전광판",
        component: () => import("@/views/page/ca/CAP0200"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0300",
        name: "대기번호전광판",
        component: () => import("@/views/page/ca/CAP0300"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0560_pu",
        name: "예약입력1",
        component: () => import("@/views/page/ca/CAP0560_pu"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0570_pu",
        name: "예약입력2",
        component: () => import("@/views/page/ca/CAP0570_pu"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0580_pu",
        name: "예약입력3",
        component: () => import("@/views/page/ca/CAP0580_pu"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0600_pu",
        name: "챗봇설문조사",
        component: () => import("@/views/page/ca/CAP0600_pu"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0610_pu",
        name: "챗봇완료",
        component: () => import("@/views/page/ca/CAP0610_pu"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAP0620_pu",
        name: "챗봇상담대기",
        component: () => import("@/views/page/ca/CAP0620_pu"),
        beforeEnter: beforeAuth(false),
      },

    ],
  },
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/home",
        name: "홈",
        component: Home,
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/commend",
        name: "설치",
        // component: Commend,
        component: () => import("@/views/help/Commend"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/layout",
        name: "구조",
        // component: Layout,
        component: () => import("@/views/help/Layout"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/menual",
        name: "사용법",
        // component: Menual,
        component: () => import("@/views/help/Menual"),
        beforeEnter: beforeAuth(true),
      },

      {
        path: "/sample",
        name: "기능샘플",
        // component: Sample,
        component: () => import("@/views/help/Sample"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/iaList",
        name: "화면목록",
        // component: IaList,
        component: () => import("@/views/help/IaList"),
        beforeEnter: beforeAuth(true),
      },

      {
        path: "/M010101",
        name: "M010101",
        component: () => import("@/views/page/M010101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M010102",
        name: "M010102",
        component: () => import("@/views/page/M010102"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M010201",
      //   name: "M010201",
      //   component: () => import("@/views/page/M010201"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M010202",
        name: "M010202",
        component: () => import("@/views/page/M010202"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M010203",
        name: "M010203",
        component: () => import("@/views/page/M010203"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M010204",
        name: "M010204",
        component: () => import("@/views/page/M010204"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110101",
        name: "M110101",
        component: () => import("@/views/page/M110101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110102",
        name: "M110102",
        component: () => import("@/views/page/M110102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110201",
        name: "M110201",
        component: () => import("@/views/page/M110201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110202",
        name: "M110202",
        component: () => import("@/views/page/M110202"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110203",
        name: "M110203",
        component: () => import("@/views/page/M110203"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110204",
        name: "M110204",
        component: () => import("@/views/page/M110204"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110301",
        name: "M110301",
        component: () => import("@/views/page/M110301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110302",
        name: "M110302",
        component: () => import("@/views/page/M110302"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110303",
        name: "M110303",
        component: () => import("@/views/page/M110303"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110401",
        name: "M110401",
        component: () => import("@/views/page/M110401"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110402",
        name: "M110402",
        component: () => import("@/views/page/M110402"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110403",
        name: "M110403",
        component: () => import("@/views/page/M110403"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M110403",
      //   name: "M110403",
      //   component: () => import("@/views/page/M110403"),
      //   beforeEnter: beforeAuth(true),
      // },
      // {
      //   path: "/M110404",
      //   name: "M110404",
      //   component: () => import("@/views/page/M110404"),
      //   beforeEnter: beforeAuth(true),
      // },
      // {
      //   path: "/M110405",
      //   name: "M110405",
      //   component: () => import("@/views/page/M110405"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M110501",
        name: "M110501",
        component: () => import("@/views/page/M110501"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110601",
        name: "M110601",
        component: () => import("@/views/page/M110601"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110602",
        name: "M110602",
        component: () => import("@/views/page/M110602"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110603",
        name: "M110603",
        component: () => import("@/views/page/M110603"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110701",
        name: "M110701",
        component: () => import("@/views/page/M110701"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110801",
        name: "M110801",
        component: () => import("@/views/page/M110801"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M110802",
        name: "M110802",
        component: () => import("@/views/page/M110802"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M210101",
      //   name: "M210101",
      //   component: () => import("@/views/page/M210101"),
      //   beforeEnter: beforeAuth(true),
      // },
      // {
      //   path: "/M210102",
      //   name: "M210102",
      //   component: () => import("@/views/page/M210102"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M310101",
        name: "M310101",
        component: () => import("@/views/page/M310101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M310102",
        name: "M310102",
        component: () => import("@/views/page/M310102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M310201",
        name: "M310201",
        component: () => import("@/views/page/M310201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M310301",
        name: "M310301",
        component: () => import("@/views/page/M310301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410101",
        name: "M410101",
        component: () => import("@/views/page/M410101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410102",
        name: "M410102",
        component: () => import("@/views/page/M410102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410201",
        name: "M410201",
        component: () => import("@/views/page/M410201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410301",
        name: "M410301",
        component: () => import("@/views/page/M410301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M410401",
        name: "M410401",
        component: () => import("@/views/page/M410401"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M510101",
        name: "M510101",
        component: () => import("@/views/page/M510101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M510201",
        name: "M510201",
        component: () => import("@/views/page/M510201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M510301",
        name: "M510301",
        component: () => import("@/views/page/M510301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M6101",
        name: "M6101",
        component: () => import("@/views/page/M6101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M6102",
        name: "M6102",
        component: () => import("@/views/page/M6102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710101",
        name: "M710101",
        component: () => import("@/views/page/M710101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710102",
        name: "M710102",
        component: () => import("@/views/page/M710102"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710103",
        name: "M710103",
        component: () => import("@/views/page/M710103"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710104",
        name: "M710104",
        component: () => import("@/views/page/M710104"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710201",
        name: "M710201",
        component: () => import("@/views/page/M710201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710202",
        name: "M710202",
        component: () => import("@/views/page/M710202"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710203",
        name: "M710203",
        component: () => import("@/views/page/M710203"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710204",
        name: "M710204",
        component: () => import("@/views/page/M710204"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710205",
        name: "M710205",
        component: () => import("@/views/page/M710205"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710301",
        name: "M710301",
        component: () => import("@/views/page/M710301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710401",
        name: "M710401",
        component: () => import("@/views/page/M710401"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M710402",
        name: "M710402",
        component: () => import("@/views/page/M710402"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810101",
        name: "M810101",
        component: () => import("@/views/page/M810101"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810201",
        name: "M810201",
        component: () => import("@/views/page/M810201"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810301",
        name: "M810301",
        component: () => import("@/views/page/M810301"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810401",
        name: "M810401",
        component: () => import("@/views/page/M810401"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810402",
      //   name: "M810402",
      //   component: () => import("@/views/page/M810402"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810403",
        name: "M810403",
        component: () => import("@/views/page/M810403"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810404",
      //   name: "M810404",
      //   component: () => import("@/views/page/M810404"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810501",
        name: "M810501",
        component: () => import("@/views/page/M810501"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810601",
        name: "M810601",
        component: () => import("@/views/page/M810601"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810602",
        name: "M810602",
        component: () => import("@/views/page/M810602"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810603",
        name: "M810603",
        component: () => import("@/views/page/M810603"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810604",
        name: "M810604",
        component: () => import("@/views/page/M810604"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810605",
      //   name: "M810605",
      //   component: () => import("@/views/page/M810605"),
      //   beforeEnter: beforeAuth(true),
      // },
      // {
      //   path: "/M810606",
      //   name: "M810606",
      //   component: () => import("@/views/page/M810606"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810607",
        name: "M810607",
        component: () => import("@/views/page/M810607"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810608",
        name: "M810608",
        component: () => import("@/views/page/M810608"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810609",
      //   name: "M810609",
      //   component: () => import("@/views/page/M810609"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810610",
        name: "M810610",
        component: () => import("@/views/page/M810610"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810611",
        name: "M810611",
        component: () => import("@/views/page/M810611"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810701",
        name: "M810701",
        component: () => import("@/views/page/M810701"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810702",
        name: "M810702",
        component: () => import("@/views/page/M810702"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810703",
        name: "M810703",
        component: () => import("@/views/page/M810703"),
        beforeEnter: beforeAuth(true),
      },
      // {
      //   path: "/M810704",
      //   name: "M810704",
      //   component: () => import("@/views/page/M810704"),
      //   beforeEnter: beforeAuth(true),
      // },
      {
        path: "/M810705",
        name: "M810705",
        component: () => import("@/views/page/M810705"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/M810706",
        name: "M810706",
        component: () => import("@/views/page/M810706"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/BUM0100",
        name: "사업지 관리",
        component: () => import("@/views/page/bu/BUM0100"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/BUM0200",
        name: "약관 관리",
        component: () => import("@/views/page/bu/BUM0200"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/ABC",
        name: "테스트",
        component: () => import("@/views/page/bu/ABC"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/CAM0100",
        name: "캠페인 관리",
        component: () => import("@/views/page/ca/CAM0100"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAM0200",
        name: "캠페인 설문 등록",
        component: () => import("@/views/page/ca/CAM0200"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/CAM0300",
        name: "알림톡 관리",
        component: () => import("@/views/page/ca/CAM0300"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/CAM0400",
        name: "개인상담관리",
        component: () => import("@/views/page/ca/CAM0400"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/CAM0500",
        name: "현장관리",
        component: () => import("@/views/page/ca/CAM0500"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/CUM0100",
        name: "등록고객관리",
        component: () => import("@/views/page/cu/CUM0100"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/CUM0200",
        name: "상담고객관리",
        component: () => import("@/views/page/cu/CUM0200"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/CUM0300",
        name: "고객이력관리",
        component: () => import("@/views/page/cu/CUM0300"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/STM0100",
        name: "일별고객현황",
        component: () => import("@/views/page/st/STM0100"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/STM0200",
        name: "기간별고객현황",
        component: () => import("@/views/page/st/STM0200"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/STM0300",
        name: "일별상담현황",
        component: () => import("@/views/page/st/STM0300"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/STM0400",
        name: "기간별상담현황",
        component: () => import("@/views/page/st/STM0400"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/STM0500",
        name: "캠페인별현황",
        component: () => import("@/views/page/st/STM0500"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/COM0100",
        name: "메뉴관리",
        component: () => import("@/views/page/co/COM0100"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/COM0200",
        name: "메뉴권한관리",
        component: () => import("@/views/page/co/COM0200"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/COM0300",
        name: "공통코드관리",
        component: () => import("@/views/page/co/COM0300"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/COM0400",
        name: "파트너관리",
        component: () => import("@/views/page/co/COM0400"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/COM0500",
        name: "상담사관리",
        component: () => import("@/views/page/co/COM0500"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/COM0600",
        name: "파일관리",
        component: () => import("@/views/page/co/COM0600"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/CAM0100_pu",
        name: "캠페인관리",
        component: () => import("@/views/page/ca/CAM0100_pu"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAM0300_pu",
        name: "메세지관리",
        component: () => import("@/views/page/ca/CAM0300_pu"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/CAM0400_pu",
        name: "개인상담관리",
        component: () => import("@/views/page/ca/CAM0400_pu"),
        beforeEnter: beforeAuth(false),
      },
      {
        path: "/OMM0100",
        name: "입주캠페인관리",
        component: () => import("@/views/page/om/OMM0100"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/OMM0200",
        name: "입주설문관리",
        component: () => import("@/views/page/om/OMM0200"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/OMM0300",
        name: "입주알림톡관리",
        component: () => import("@/views/page/om/OMM0300"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/OMM0400",
        name: "입주계약고객관리",
        component: () => import("@/views/page/om/OMM0400"),
        beforeEnter: beforeAuth(true),
      },
      {
        path: "/OMM0500",
        name: "입주유입고객관리",
        component: () => import("@/views/page/om/OMM0500"),
        beforeEnter: beforeAuth(true),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
