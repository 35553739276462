<template>
  <v-dialog v-model="alert.alertDialogToggle" max-width="420">
    <!--persistent 옵션: 바탕 또는 esc키 눌러도 안닫히게 하는 옵션-->
    <v-card class="alert-wrap">
      <template>
        <v-btn
          v-if="
            alert.type == 'default' ||
            alert.type == 'callback' ||
            alert.type == 'reserveCall'
          "
          depressed
          icon
          x-small
          absolute
          right
          class="svg-close20"
          @click="alert.alertDialogToggle = false"
        >
        </v-btn>
      </template>
      <v-card-text v-if="alert.type == 'default' || alert.type == 'confirm'">
        <v-icon :class="alert.iconClass"> </v-icon>
        <div
          :class="alert.type === 'confirm' ? 'alert-btn-ct' : ''"
          v-html="newLine(alert.msg)"
        ></div>
      </v-card-text>
      <v-card-text v-if="alert.type == 'callback'">
        <div class="alert-callback-title">콜백 {{ alert.count }} 건</div>
        <div class="alert-callback-text">
          콜백이 할당되었습니다.
          <span class="alert-callback-sub">콜백을 처리해주세요.</span>
        </div>
      </v-card-text>
      <v-card-text v-if="alert.type == 'reserveCall'">
        <div class="alert-callback-title">예약콜 {{ alert.count }} 건</div>
        <div class="alert-callback-text">
          예약콜 {{ alert.reserveCallTime }}분 전입니다.
        </div>
      </v-card-text>
      <v-card-actions class="btn-wrap" v-if="alert.type === 'confirm'">
        <div>
          <v-btn outlined large class="btn-default" @click="alert.callNo">
            아니오
          </v-btn>
        </div>
        <div>
          <v-btn outlined large class="btn-point" @click="alert.callYes">
            예
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "alertDefault",
  data: () => ({}),
  computed: {
    ...mapGetters({
      alert: "alertStore/GE_ALERT",
    }),
  },
  methods: {
    newLine(title) {
      if (!title) return;
      return title.split("\n").join("<br>");
    },
  },
};
</script>

<style></style>
