<template>
  <div class="root-ct-wrap">
    <keep-alive :include="alive_tabs">
      <router-view :key="$route.path.replace('/', '')" />
    </keep-alive>
    <!-- :exclude="no_cash_tab" -->
    <div class="text-center">
      <v-dialog v-model="progress_bar" content-class="common-progress-dialog">
        <div class="loader-wrap">
          <!-- temp01 -->
          <div class="loader"></div>
          <div class="loader-icn-wrap ani">
            <div class="loader-icn01" ></div>
            <div class="loader-icn02" ></div>
            <div class="loader-icn03" ></div>
            <div class="loader-icn04" ></div>
            <div class="loader-icn05" ></div>
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loader: false,
        aniClass: null,
      };
    },
    methods: {

    },
    mounted() {
      //mixin에 있는 default_tab 기준으로 탭을 초기화 한다
      this.$store.dispatch("commonStore/AC_COMMON_INIT_TAB", this.default_tab);
    },
    computed: {
      active_tab_num: {
        get() {
          return this.ge_active_tab_num;
        },
        set() {},
      },
      alive_tabs: {
        get() {
          let getTabs = this.tabs;

          let setTabs = [];
          for (let i = 0; i < getTabs.length; i++) {
            setTabs.push("MENU_" + getTabs[i].id);
          }
          return setTabs;
        },
        set() {},
      },
    },
    watch: {


    }
  };
</script>

<style>
  .root-ct-wrap {
    display: flex;
    flex-direction: column;
  }
  .common-progress-dialog {
    box-shadow: none;
    text-align: center;
    overflow: hidden;
  }
</style>
