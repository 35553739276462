/**
 * websocket 관련 비지니스 
 */

import Vue      from 'vue';
import SockJS   from "sockjs-client";
import Stomp    from "webstomp-client";
 
import _ from 'lodash';

import { mixin } from "@/mixin/mixin.js"

export const stompUtil = new Vue({
     mixins: [mixin],
     data() {
         return {
             stompClient : '',
             contactInfo : Vue.prototype.store != null ? Vue.prototype.store.getters.getContactInfo() : '',
             connected   : false,
             baseSocketUrl: process.env.VUE_APP_SOCKET_URL,
             token : '',
             userId : '',
             aspNewCustKey:''
         };
     },
     methods: {
         
         /**
          * connnected 여부 
          * @returns 
          */
         isConnected() {
             return this.connected;
         },
  
         /**
          * connnected 값 셋팅
          * @param {*} val 
          */
         setConnected(val) {
             this.connected = val;
         },
         
         /**
          * websocket connect 
          * @param {*} connectCallback 연결 성공 콜백 
          * @param {*} errorCallback  연결 실패 콜백 ( 재연결 시도 )
          *  @param {*} store
          */ 
         //connect(connectCallback, errorCallback, contactInfo, store) {
         connect(connectCallback, errorCallback, store) {
             if (!this.connected) {
                 const sockJsUrl = this.baseSocketUrl + 'wsocket'
                 
                 let options = {debug: false, protocols: Stomp.VERSIONS.supportedProtocols()};
 
                 this.socket = new SockJS(sockJsUrl);
                 this.stompClient = Stomp.over(this.socket, options);
                 //this.contactInfo = contactInfo;
                  
                 this.stompClient.reconnect_delay = 3000;
                 this.stompClient.heartbeat.outgoing = 3000;
                 this.stompClient.heartbeat.incoming = 3000;
                 
                 if(process.env.NODE_ENV === 'production') {
                     this.stompClient.debug = function() {};
                 }
                 
                 this.stompClient.connect(
                     { 
                         "token":this.token, "Authorization":  "bearer " + window.sessionStorage.getItem("token"), "userId" : this.userId, "aspCustKey" : this.aspNewCustKey
                     },
                     frame => { 
                         
                         this.setConnected(true);
                         connectCallback(frame) 
                     },
                     error => { 
 
                         console.log("stompUtil connect LostConnect errorCallback >>>> " + this.token); 
 
                         this.setConnected(false);
                         errorCallback(error) 
                     },
                 );
 
                 this.stompClient.ws.onclose = function() {
                     errorCallback('close event');
                 };
                 
             }
             else {
                 connectCallback();
             }
         },
          
         /**
          * 웹소켓 연결 종료 
          */
         disconnect() {
 
             if(this.stompClient  != null) {
 
                 this.stompClient.disconnect();
                 this.setConnected(false);
             }
         },
           
         /**
          * SUB 구독 
          * @param {*} uri               구독 uri
          * @param {*} chatMessage       구독시 data 
          * @returns 
          */ 
         subscribe(uri, chatMessage) {
            
            console.log("stompUtil subscribe >>>> "); 

            return this.stompClient.subscribe(uri, message => { 
                this.onmessage(message); 
            }, chatMessage);
         },
  
         /**
          * 재구독 재연결 후 ( 네트웍 단절 후 구독 다시 해줘야 함.)
          */ 
         subscribeAfterReconnect(subList) {
             console.log("subList", subList);
             console.log("subList", subList.length);
             let subListLen  = subList.length;   
             
             if(subList != null && subListLen > 0) {
                 
                 let i = 0;
 
                 for(; i<subListLen; i++) {
                     var subObj = subList[i];
                     this.subscribe(subObj.uri, subObj.chatMessage);
                 }
             }
         },
 
         /**
          * 메시지 인입 
          * @param {*} message 
          */ 
         onmessage(message) {
            let dest = message.headers.destination;
            console.log("stompUtil subscribe onmessage >>>> " + message);

            if (_.isMatch(message.headers.destination, '/queue/ready/')) {
                console.log("stompUtil subscribe /queue/ready/ busReadyCallback >>>> ");
            }
            else {
                console.log("stompUtil subscribe busInoutCallback >>>> ");
            }
         },

         // 메시지 전송 
         sendMessage(MSG_INFO,CHAT_INFO, chatmsg, msgObj) {
             
             console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ 14");
 
              // error 처리 필요함.
              return new Promise((resolve , reject) => { 

                  var sendData = {
                      HEADER: { in_out: 'sendMessage' }
                      , DATA: [{
                          TALK_CONTACT_ID: CHAT_INFO.talk_contact_id
                          , sender_key: CHAT_INFO.talk_sender_key != null && CHAT_INFO.talk_sender_key != '' ? CHAT_INFO.talk_sender_key : CHAT_INFO.asp_sender_key
                          , message_type: MSG_INFO.message_type//'TX'
                          , serial_number: ''// 서버처리로 변경 
                          , USER_ID: CHAT_INFO.userId
                          , SNDRCV_CD: MSG_INFO.SNDRCV_CD //'SND'
                          , PROC_ID: CHAT_INFO.userId
                          , type: MSG_INFO.type  //'message'
                          , message: MSG_INFO.msg
                          , call_typ_cd: CHAT_INFO.call_typ_cd
                          , consultEnd : MSG_INFO.consultEnd != '' ? MSG_INFO.consultEnd : ''
                          , IMAGE_TALK_PATH : MSG_INFO.IMAGE_TALK_PATH != '' ? MSG_INFO.IMAGE_TALK_PATH : ''
                          , image : MSG_INFO.image != '' ? MSG_INFO.image : ''
                          , FILE_GROUP_KEY : MSG_INFO.FILE_GROUP_KEY != '' ? MSG_INFO.FILE_GROUP_KEY : ''
                          , FILE_KEY : MSG_INFO.FILE_KEY != '' ? MSG_INFO.FILE_KEY : ''
                          , UNTACT_ID : MSG_INFO.UNTACT_ID != '' ? MSG_INFO.UNTACT_ID : ''
                          , FILE_BLOB : MSG_INFO.FILE_BLOB != '' ? MSG_INFO.FILE_BLOB : ''
                          , FILE_EXTS : MSG_INFO.FILE_EXTS != '' ? MSG_INFO.FILE_EXTS : ''
                          , ASP_NEWCUST_KEY : CHAT_INFO.aspNewCustKey
                      }]
                  };
  
                  var sendDataStr = JSON.stringify(sendData);
  
                  // console.log("sendDataStr >> " + sendDataStr);
                  this.stompClient.send("/app/inout/chat/message", sendDataStr, { "token": CHAT_INFO.token, "userKey": CHAT_INFO.talk_user_key });
  
                  resolve();
              });
         },
      },
  })
  